$(function() {

    $('.top').on('click', '.btn', function() {

        $('html, body').animate({
            scrollTop: $('.about').offset().top
        }, 400);

    });

});

$(function() {

    $(window).scroll(function() {

        var top = $(window).scrollTop();

        if ($('#course').length) {

            if ($('#course').position().top < top) {

                var percentNumberStep = $.animateNumber.numberStepFactories.append('%');

                $('#course').find('.stage').each(function(i, element) {

                    if ($(element).find('.steps').text() == '0%') {

                        $(element).find('.steps').animateNumber({
                            number: $(element).find('.steps').data('percent'),
                            easing: 'easeInQuad',
                            numberStep: percentNumberStep
                        });

                        $(element).find('.progress-bar').width($(element).find('.steps').data('percent') + '%');

                    }

                });

            }

        }

    });

});

$(function() {

    var fired = [];

    fired[0] = true;
    fired[1] = true;
    fired[2] = true;
    fired[3] = true;

    $(window).scroll(function() {

        var top = $(window).scrollTop();

        $('.metric').each(function(i) {

            var element = $(this);

            if (element.position().top - 200 < top && fired[i]) {

                fired[i] = false;

                if (element.find('.number').text() == '0') {

                    element.find('.number').animateNumber({
                        number: element.find('.number').data('number'),
                        easing: 'easeInQuad'
                    });

                }

            }

        });

    });

});

$(function() {

    $('.page-immobiles').on('show.bs.dropdown', '.dropdown', function() {

        $(this).closest('.row').find('.thumbnail').not($(this).closest('.thumbnail')).addClass('opacity');

    });

    $('.page-immobiles').on('hide.bs.dropdown', '.dropdown', function() {

        $('.page-immobiles').find('.thumbnail').removeClass('opacity');

    });

});

$(function() {

    $('.banner').find('.slider').slick({
        autoplay: true,
        prevArrow: $('.banner').find('.prev'),
        nextArrow: $('.banner').find('.next')
    });

});

$(function() {

    $('.enterprises.slick').slick({
        infinite: false,
        swipe: false,
        prevArrow: '.enterprises-preview',
        nextArrow: '.enterprises-next'
    });

    $('.enterprises').find('.builders').each(function() {

        $(this).find('.builder').slick({
            prevArrow: $(this).find('.prev'),
            nextArrow: $(this).find('.next'),
            fade: true
        });

    });

});

$(function() {

    $('.menu-open').on('click', function() {
        $('.site-header').find('.menu').toggleClass('open');
    });

    $('.menu-close').on('click', function() {
        $('.site-header').find('.menu').toggleClass('open');
    });

});

$(function() {

    $('.showcase').on('click', '.choose .button', function() {

        if ( ! $(this).hasClass('active')) {
            $(this).toggleClass('active').siblings('.button').toggleClass('active');
        }

    });

});

$(function() {

    /*----------  nicescroll  ----------*/

    $('.scroll').niceScroll({
        cursorborder: 'none',
        cursorborderradius: '0',
        cursorcolor: '#aaaaaa'
    });

    /*----------  bootstrap  ----------*/

    // prevents dropdown hidden on click inside menu
    $('.dropdown-menu').click(function(e) {
        e.stopPropagation();
    });

    // popover init
    $('.popover').popover({
        html: true
    });

    /*----------  slick  ----------*/

    $('.slick').each(function() {

        var preview = $(this).find('.btn-preview');
        var next = $(this).find('.btn-next');

        $(this).find('.list').slick({
            autoplay: true,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            prevArrow: preview,
            nextArrow: next,
        });

    });

});

$(function() {

    $('.page-immobiles').find('.slider').each(function() {

        $(this).slick({
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1
        });

    });

});

$(function() {

    setInterval(function() {
        $('.page .top .btn').addClass('bounce');

        setTimeout(function() {
            $('.page .top .btn').removeClass('bounce');
        }, 2000);
    }, 5000);

    if ($('.page .top').height() > $(window).height()) {

        $('.page .top .btn').css('position', 'fixed');

    }

    $(window).resize(function() {

        if ($('.page .top').height() > $(window).height()) {

            $('.page .top .btn').css('position', 'fixed');

        }

    });

    $(window).scroll(function() {

        if ($('.page .top').height() > $(window).height()) {

            $('.page .top .btn').css('position', 'fixed');

            if (($(window).height() + $(window).scrollTop()) > $('.page .top').outerHeight()) {
                $('.page .top .btn').css('position', 'absolute');
            } else {
                $('.page .top .btn').css('position', 'fixed');
            }

        }

    });

});