/*===========================================
=            configurations file            =
===========================================*/

var config = {

    system: {

        base: 'http://localhost:8000'

    }

};

/*-----  End of configurations file  ------*/

