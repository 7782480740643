(function() {

    /*----------  register contact  ----------*/

    (function() {

        $('.form').each(function(i, form) {

            $(form).ajaxForm({

                dataType: 'json',

                beforeSubmit: function() {

                    $(form).find('.form-group').removeClass('has-error').find('.help-block').remove();

                    $(form).find('[type=submit]').button('loading');

                },

                success: function(response) {

                    var alert = $('<div class="alert alert-success">Seu contato foi enviado com sucess. Em breve entraremos em contato. Obrigado!</div>').prependTo(form);

                    setTimeout(function() {
                        $(alert).fadeTo('fast', 0, function() {
                            $(this).slideUp('fast');
                        });
                    }, 5000);

                    $(form).find('input, textarea').val('').prop('checked', false);

                    $(form).find('[type=submit]').button('reset');

                },

                error: function(response) {

                    $.each(response.responseJSON, function(field, messages) {

                        var message = messages.join('');

                        $(form).find('[name=' + field + ']').closest('.form-group').addClass('has-error').append('<span class="help-block">' + message + '</span>');

                    });

                    $(form).find('[type=submit]').button('reset');

                }

            });

        });

    })();

    $(function() {

        $('.form-newsletter').ajaxForm({

            dataType: 'json',

            beforeSubmit: function() {

                $('.form-newsletter').find('[type=submit]').button('loading');

            },

            success: function(response) {

                $('.form-newsletter').find('[type=submit]').button('reset');

                $('.form-newsletter').find('[name=email]').val('');

                alert('Seu e-mail foi cadastrado com sucess. Em breve enviaremos novidades para você. Obrigado!');

            },

            error: function(response) {

                $('.form-newsletter').find('[type=submit]').button('reset');

                alert('Informe um e-mail válido!');

            }

        });

    });

})();