/*----------  input mask  ----------*/


$('.mask-date').inputmask({
    mask: 'd/m/y'
});

$('.mask-phone').inputmask({
    mask: '(99) 99999999[9]',
    greedy: false
});

$('.mask-cpf').inputmask({
    mask: '999.999.999-99'
});

$('.mask-zip').inputmask({
    mask: '99999-999'
});

$('.mask-zip-search').inputmask({
    mask: '99999-999',
    oncomplete: function(input) {

        var input = $(this), form = $(input).closest('form');

        $(form).find('.input-district').addClass('disabled').prop('disabled', true);
        $(form).find('.input-city').addClass('disabled').prop('disabled', true);
        $(form).find('.input-street').addClass('disabled').prop('disabled', true);
        $(form).find('.input-state').addClass('disabled').prop('disabled', true);

        $.get(('/zip/').concat(input.val()), function(address) {

            $(form).find('.input-number').focus();

            $(form).find('.input-district').val(address.bairro).removeClass('disabled').prop('disabled', false);
            $(form).find('.input-city').val(address.cidade).removeClass('disabled').prop('disabled', false);
            $(form).find('.input-street').val(address.logradouro).removeClass('disabled').prop('disabled', false);
            $(form).find('.input-state').val(address.uf).removeClass('disabled').prop('disabled', false);

        });

    }
});